import React from "react"
import { graphql } from "gatsby"
import { Parallax } from 'react-scroll-parallax'

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { ProductList, AboutLead, USPBox, StoryBlock, ContactInfoBox } from "../components/Slices"
import Contact from "../components/Layout/Contact"
import Footer from "../components/Layout/Footer"
import Decor from "../components/Subcomponent/Decor"

const DefaultPage = ({ data: { prismicPage } }) => {
	const { data } = prismicPage

	return (
		<Layout>
			<SEO title={data.title.text}/>
			<article id="defaultPage">		
				<div className="container">
					<Parallax y={[-20, 20]}>
						<h1 className="page-lead">{data.head_lead.text}</h1>
					</Parallax>
				</div>
				<PageSlices slices={ data.body } />
			</article>
			{(() => {
				if (data.footer_contact_block === "Display") {
					return (
						<Contact/>
					)
        }
      })()}
			<Footer/>
			<Decor />
		</Layout>
	)
}

const PageSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch(slice.slice_type) {
				case 'about_lead': return (
          <div key={ index } className="slice-wrapper">
            { <AboutLead slice={ slice } /> }
          </div>
				)
				case 'usp_box': return (
          <div key={ index } className="slice-wrapper">
            { <USPBox slice={ slice } /> }
          </div>
				)
        case 'product_list': return (
          <div key={ index } className="slice-wrapper">
            { <ProductList slice={ slice } /> }
          </div>
				)
				case 'story_block': return (
          <div key={ index } className="slice-wrapper">
            { <StoryBlock slice={ slice } /> }
          </div>
				)
				case 'contact_info_box': return (
          <div key={ index } className="slice-wrapper">
            { <ContactInfoBox slice={ slice } /> }
          </div>
				)
        default: return
      }
    })();
    return res;
  })
}

export default DefaultPage

export const pageQuery = graphql`
	query PageBySlug($id: String!) {
		prismicPage(id: { eq: $id }) {
			data {
				title {
					text
				}
				head_lead {
					text
				}
				footer_contact_block
				body {
					... on PrismicPageBodyAboutLead {
						slice_type
						primary {
							lead {
								text
							}
						}
						items {
							image {
								url
								alt
							}
							position
						}
          }
          ... on PrismicPageBodyUspBox {
						slice_type
						items {
							icon
							lead {
								text
							}
							heading {
								text
							}
						}
					}
					... on PrismicPageBodyProductList {
						slice_type
						primary {
							start_from
						}
					}
					... on PrismicPageBodyStoryBlock {
						slice_type
						items {
							alignment
      				orientation
							cover_image {
								url
							}
							story_copy {
								html
							}
							story_title {
								text
							}
						}
					}
					... on PrismicPageBodyContactInfoBox {
						slice_type
						primary {
							address
							google_maps_link
							email
							phone
						}
          }
				}
			}
		}
	}
`