import React from 'react'
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import ProductCard from "../Subcomponent/ProductCard"

const ProductListSkeleton = ({ slice, edges }) => {
  const items = edges;
  const itemsSorted = items.sort(function(a, b){
    return a.node.data.sort - b.node.data.sort;
  });
  const align = slice.primary.start_from

  return (
    <div className="slice-productlist">
      <div className="container">
        <div className={align === "Right" ? 'wrapper start--right' : 'wrapper'}>
          {(() => {
            if (align === "Right") {
              return (
                <div className="start-spacer"></div>
              )
            }
          })()}
          {itemsSorted.map((item, i) => (
            <ProductCard item={item} maxColor="9" />
          ))}
        </div>
      </div>
    </div>
  )
}

ProductListSkeleton.propTypes = {
  edges: PropTypes.array,
};

const ProductList = props => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicProduct {
          edges {
            node {
              data {
                name {
                  text
                }
                sort
                phisical_scale
                featured_variant
                variant {
                  variant_color
                  variant_name {
                    text
                  }
                  variant_image {
                    thumbnails {
                      thumbnail {
                        url
                      }
                    }
                  }
                }
              }
              uid
              id
            }
          }
        }
      }
    `}
    render={data => (
      <ProductListSkeleton {...data.allPrismicProduct} {...props} />
    )}
  />
);

export default ProductList
