import React from "react"
import { Parallax } from 'react-scroll-parallax';

import { Pattern } from './SVG'

const Decor = ({ position }) => {
  return (
    
      <div className="decor">
        <div className="decor-position">
          <Parallax y={[0, 150]}>
            <Pattern />
          </Parallax>
        </div>
      </div>
  )
};

export default Decor