import React from 'react'
import Fade from 'react-reveal/Fade'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const ContactInfoBox = ({ slice }) => {

  function sendMail() {
    trackCustomEvent({
      category: "Contact",
      action: "Email Clicked",
      label: "Contact email clicked"
    })
		window.location.href = "mailto:" + slice.primary.email;
  }
  
  function callMobile() {
    trackCustomEvent({
      category: "Contact",
      action: "Mobile Clicked",
      label: "Contact phone number clicked"
    })
		window.location.href = "tel:" + slice.primary.phone;
	}

  return (
    <div className="slice-contactinfobox">
      <div className="container">
        <div  className="wrapper">
        
          <Fade bottom duration={800} delay={200} distance="30px">
          <div className="contactinfobox-item contactinfobox-item--tile">
            <div className="row">
              <h4>Telephelyünk</h4>
              <h2>{slice.primary.address}</h2>
            </div>
            <a
              className="button button--primary"
              href={slice.primary.google_maps_link}
              target="_blank"
              rel="noreferrer"
            >
              Térkép
            </a>
          </div>
          </Fade>

          <Fade bottom duration={800} delay={400} distance="30px">
          <div className="contactinfobox-item">
            <div className="row">
              <h4>E-mail</h4>
              <h2
                className="event"
                onClick={event => sendMail()}
              >
                {slice.primary.email}
              </h2>
            </div>
            <div className="row">
              <h4>Telefon</h4>
              <h2
                className="event"
                onClick={event => callMobile()}
              >
                {slice.primary.phone}
              </h2>
            </div>
          </div>
          </Fade>

          <Fade bottom duration={800} delay={600} distance="30px">
          <div className="contactinfobox-item contactinfobox-item--full">
            <div className="row">
              <h4>Értékesítési pont</h4>
              <h2>Flora Hungaria Virágpiac (910. stand)</h2>
            </div>
            <a
              className="button button--primary"
              href="https://goo.gl/maps/QU7iMU6bppRXEHcd8"
              target="_blank"
              rel="noreferrer"
            >
              Térkép
            </a>
          </div>
          </Fade>

        </div>
      </div>
    </div>
  )
}

export default ContactInfoBox
