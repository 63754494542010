import React from 'react'
import Fade from 'react-reveal/Fade'
import { Icon } from '../Subcomponent/SVG';

const USPBox = ({ slice }) => {
  return (
    <div className="slice-uspbox">
      <div className="container">
        {slice.items.map((item, i) => (

          <Fade bottom duration={800} delay={250} distance="30px" key={i}>

            <div className="uspbox-item">
              <div className={"icon"}>
                <Icon type={item.icon} />
              </div>

              <div className="content">
                <h4>{item.heading.text}</h4>
                <p>{item.lead.text}</p>
              </div>
            </div>

          </Fade>
        
        ))}
      </div>
    </div>
  )
}

export default USPBox
