import React from 'react'
import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax'

const AboutLead = ({ slice }) => {

  return (
    <div className="slice-aboutlead">
      <div className="container">

        <ul className="photo-grid">
          {slice.items.map((photo, i) => (
            <Fade bottom duration={1200} delay={i * 250} distance="30px" key={i}>
              <li
                className={"photo-grid-item position-" + photo.position}
                key={i}
                style={{ backgroundImage: 'url(' + photo.image.url + '' }}
              ></li>
            </Fade>
          ))}
        </ul>
        
        <Parallax y={[15, -15]}>
        <div className="lead-box">
          <div className="content">
            <h3>{slice.primary.lead.text}</h3>
          </div>
        </div>
        </Parallax>
      
      </div>
    </div>
  )
}

export default AboutLead
