import React from 'react'
import { Parallax } from 'react-scroll-parallax';

const StoryBlock = ({ slice }) => {
  return (
    <div className="slice-storyblock">
      <div className="container">
        {slice.items.map((item, i) => (

          <div className={"storyblock-item " + item.alignment} key={i}>

            <Parallax y={[0, 30]}>
            <div
              className={ "cover-image " + item.orientation}
              style={{ backgroundImage: 'url(' + item.cover_image.url + '' }}
            ></div>
            </Parallax>

            <Parallax y={[0, -30]}>
            <div className="block">
              <h1 className="story-title">
                {item.story_title.text}
              </h1>
              <div
                className="story-copy" 
                dangerouslySetInnerHTML={{ __html: item.story_copy.html}}
              />
            </div>
            </Parallax>

          </div>
        
        ))}
      </div>
    </div>
  )
}

export default StoryBlock
